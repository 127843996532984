import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Text } from 'components/atoms';

const ExpandableSectionsItem = ({ data, className }) => {
  const { description, text } = data || {};
  const [active, setActive] = useState(false);
  const descriptionRef = useRef(null);

  useEffect(() => {
    const textElement = descriptionRef.current;

    if (active) {
      textElement.style.maxHeight = textElement.scrollHeight + 'px';
      textElement.addEventListener(
        'transitionend',
        () => (textElement.style.maxHeight = 'unset'),
        {
          once: true,
        }
      );
    } else {
      textElement.style.maxHeight = textElement.scrollHeight + 'px';
      textElement.addEventListener(
        'transitionstart',
        () => (textElement.style.maxHeight = null),
        {
          once: true,
        }
      );
      textElement.addEventListener(
        'transitionend',
        () => (textElement.style.maxHeight = null),
        {
          once: true,
        }
      );
    }
  }, [active]);

  return (
    <div
      role="button"
      tabIndex="0"
      className={cn(
        'expandable-sections-item',
        { 'expandable-sections-item_active': active },
        [className]
      )}
      onClick={() => setActive(!active)}
      onKeyPress={() => setActive(!active)}
    >
      <Text className="body-sm body-sm_bold expandable-sections-item__title">
        {text}
      </Text>
      <i className="icon-expand-arrow expandable-sections-item__arrow" />
      <div
        ref={descriptionRef}
        className={cn('expandable-sections-item__description', [className])}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};

ExpandableSectionsItem.defaultProps = {
  className: '',
};

ExpandableSectionsItem.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default ExpandableSectionsItem;
