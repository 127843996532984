import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column } from 'components/atoms';
import ExpandableSectionsItem from 'components/molecules/ExpandableSectionsItem';

const ExpandableSections = ({ data, className }) => {
  const { sections, caption } = data || {};
  const { title } = caption;

  const sectionsList =
    sections &&
    sections.map((item, index) => (
      <ExpandableSectionsItem data={item} key={index} />
    ));

  return (
    <Section className={cn('expandable-sections', [className])}>
      <Container>
        <Row>
          <Column className="offset-lg-1" lg="6">
            <div
              className="expandable-sections__title"
              dangerouslySetInnerHTML={{ __html: title }}
            ></div>
          </Column>
          <Column className="offset-lg-1" lg="10">
            <div className="expandable-sections__block">{sectionsList}</div>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

ExpandableSections.defaultProps = {
  className: '',
};

ExpandableSections.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default ExpandableSections;
